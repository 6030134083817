import { useParams } from "react-router-dom";
import siteConfig from "./siteConfig";

export default function ComputerHeader() {
  const { site_address } = useParams();
  const siteData = siteConfig[site_address];
  const { siteName, askingPrice, acerage } = siteData;
  return (
    <div
      id="BlackHeaderBar"
      className="grid grid-cols-2 w-full bg-black text-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.60)] text-lg font-bold p-3 pr-10 sticky top-0 z-20"
    >
      <a href="#top" className="flex items-center portrait-group md:pl-10">
        <div>{siteName}</div>
        <div className="mt-1 text-[#F0FA5F] text-sm md:text-base libre-franklin-Bold ml-2">
          Total Acres: {acerage} - Asking Price: {askingPrice}
        </div>
      </a>

      <div className="flex flex-row justify-self-end self-end justify-center">
        <a href="#detailsAnchor" className="hover:text-[#F0Fa5F]">
          Details
        </a>
        <a href="#imagesAnchor" className="pl-6 hover:text-[#F0Fa5F]">
          Images
        </a>
        <a href="#mapsAnchor" className="pl-6 hover:text-[#F0Fa5F]">
          Map
        </a>
        <a href="#contactAnchor" className="pl-6 hover:text-[#F0Fa5F]">
          Contact
        </a>
      </div>
    </div>
  );
}
