import React from "react";
import tree from "../components/images/tree_logo.png";

export default function LandscapeHeaderMobile() {
  //const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div
      id="BlackHeaderBar"
      className="flex justify-start bg-black text-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.60)] text-lg font-bold p-2 sticky top-0 z-20"
    >
      <div className="flex">
        <img src={tree} alt="tree" height={60} width={60} />
        <div className="ml-2 pt-1">
          <div className="flex justify-start text-3xl text-dark-gray libre-franklin-Bold">
            Parcel Style
          </div>
          <div className="text-lg text-dark-gray libre-franklin-Regular">
            Upgrade your land advertisement
          </div>
        </div>
      </div>

      {/*
      {isMenuOpen && (
        <div className="bg-black text-white p-3 l-header">
          <a href="#services" className="block hover:text-gray-300">
            Services
          </a>
          <a href="#pricing" className="block hover:text-gray-300">
            Pricing
          </a>
          <a href="#portfolio" className="block hover:text-gray-300">
            Portfolio
          </a>
          <a href="#contact" className="block hover:text-gray-300">
            Contact
          </a>
        </div>
      )} */}
    </div>
  );
}
