import { useParams } from "react-router-dom";
import siteConfig from "./siteConfig";

export default function MobileHeader() {
  const { site_address } = useParams();
  const siteData = siteConfig[site_address];
  const { siteName, askingPrice, acerage } = siteData;

  return (
    <div
      id="BlackHeaderBar"
      className="w-full bg-black text-white text-lg font-bold p-3 sticky top-0 z-20"
    >
      <div className="container mx-auto md:flex md:justify-between ">
        <a href="#top" className="flex items-center portrait-group md:pl-10">
          <div>{siteName}</div>
          <div className="mt-1 text-[#F0FA5F] text-sm md:text-base libre-franklin-Bold ml-2">
            Total Acres: {acerage} - Asking Price: {askingPrice}
          </div>
        </a>
      </div>
    </div>
  );
}
