import tree from "./images/tree_logo.png";

export default function LandscapeHeader() {
  return (
    <div
      id="BlackHeaderBar"
      className="grid grid-cols-2 w-full bg-black text-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.60)] text-lg font-bold p-3 pr-10 sticky top-0 z-20"
    >
      <a href="#top">
        <div id="#top" className="flex flex-row justify-left pl-10">
          <div>
            <img src={tree} alt="tree" height={75} width={75} />
          </div>

          <div className="text-5xl text-dark-gray libre-franklin-Bold justify-self-center self-end pl-2">
            Parcel Style
            <div className="text-xl text-dark-gray libre-franklin-Regular pt-2">
              Upgrade your land advertisment
            </div>
          </div>
        </div>
      </a>

      <div className="flex flex-row justify-self-end self-end justify-center">
        <a href="#services">
          <div className="hover:text-[#F0Fa5F]">Services</div>
        </a>
        <a href="#pricing">
          <div className="pl-6 hover:text-[#F0Fa5F]">Pricing</div>
        </a>
        <a href="#portfolio">
          <div className="pl-6 hover:text-[#F0Fa5F]">Portfolio</div>
        </a>
        <a href="#contact">
          <div className="pl-6 hover:text-[#F0Fa5F]">Contact</div>
        </a>
      </div>
    </div>
  );
}
