import React, { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import siteConfig from "./siteConfig";
import MobileHeader from "MobileHeader";
import ComputerHeader from "ComputerHeader";

const Details = React.lazy(() => import("./sub_components/Details.js"));
const Maps = React.lazy(() => import("./sub_components/Maps.js"));
const Images = React.lazy(() => import("./sub_components/Images.js"));
const Contact = React.lazy(() => import("./sub_components/Contact.js"));

const DynamicSite = () => {
  const { site_address } = useParams();
  console.log("site_address from URL:", site_address); // Debugging log

  const siteData = siteConfig[site_address];
  console.log("siteData found in siteConfig:", siteData); // Debugging log

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (!siteData) {
    return <div>Site not found</div>;
  }

  const { photos } = siteData;

  return (
    <>
    {isMobile ? <MobileHeader/> : <ComputerHeader/>}

      <Suspense
        fallback={
          <div className="h-screen flex items-center justify-center">
            <PuffLoader />
          </div>
        }
      >
        {/* Images and sections */}
        <div className="pt:3">
          <img
            className="portrait:hidden rounded bg-cover shadow-[0px_5px_5px_0px_rgba(0,_0,_0,_0.60)] hero-img"
            src={photos.feature}
            alt="feature"
            loading="eager"
          />
          <img
            className="landscape:hidden rounded bg-cover shadow-[0px_5px_5px_0px_rgba(0,_0,_0,_0.60)]"
            src={photos.featurePortrait}
            alt="feature"
            loading="eager"
          />
        </div>

        {/* eslint-disable-next-line */}
        <a id="detailsAnchor" className="anchor" style={{ top: "-70px" }}></a>
        <Details />
        {/* eslint-disable-next-line */}
        <a id="imagesAnchor" className="anchor" style={{ top: "-70px" }}></a>
        <Images />
        {/* eslint-disable-next-line */}
        <a id="mapsAnchor" className="anchor" style={{ top: "-45px" }}></a>
        <Maps />
        {/* eslint-disable-next-line */}
        <a id="contactAnchor" className="anchor" style={{ top: "-45px" }}></a>
        <Contact />
      </Suspense>
    </>
  );
};

export default DynamicSite;
