import tree from "../components/images/tree_logo.png";

export default function PortraitHeaderMobile() {
  return (
    <div
      id="BlackHeaderBar"
      className="flex justify-center bg-black text-white shadow-[0px_2px_2px_0px_rgba(0,_0,_0,_0.60)] text-lg font-bold p-2 sticky top-0 z-20"
    >
      <div className="flex">
        <div className="">
          <img src={tree} alt="tree" height={60} width={60} />
        </div>
        <div className="ml-2 pt-1">
          <div className="flex justify-start text-3xl text-dark-gray libre-franklin-Bold">
            Parcel Style
          </div>
          <div className="text-lg text-dark-gray libre-franklin-Regular">
            Upgrade your land advertisment
          </div>
        </div>
      </div>
    </div>
  );
}
