// blank template
/*
"": {
    siteName: "",
    askingPrice: "",
    acerage: "",
    photoDate: "",
    yTlink: "",
    mapLink : "",
    parcelDescription: ``,
    parcelData: {
      gps: "",
      totalAcres: "",
      landSqft: "",
      assessedYr: "",
      assessedValue: "",
      censusTract: "",
      landValue: "",
      landUseType: "",
      county: "",
      waterServiceType: "",
      parcelId: "",
      taxAmount: "",
    },
    contact: {
      name: "",
      email: "",
      phone: "tel:+1",
      phoneDisplay: "",
      website: "",
      displayWebsite : "",
      photoLink: "",
    },
    photos: {
      feature: ``,
      featurePortrait: ``,
      portfolio: ``,
      parcel: ``,
    },
    panoPhotos:{
      sideA:{
        labeledParcel: ``,
        pano: ``},
      sideB:{
        labeledParcel: ``,
        pano: ``},
    },
    lightboxPhotos: {
      sidea1: ``,
      sidea2: ``,
      sidea3: ``,
      sidea4: ``,
      sidea5: ``,
      sidea6: ``,
      sideb1: ``,
      sideb2: ``,
      sideb3: ``,
    },
  },
*/

const siteConfig = {
  "200-Hansford-Lane-Seaford-VA-23696": {
    siteName: "200 Hansford Lane, Seaford, VA 23696",
    askingPrice: "$329,000",
    acerage: "2.51",
    photoDate: "July 23rd 2024",
    yTlink: "https://www.youtube.com/embed/rnFNeV9EPhE?si=Oua3fIyv-IL5SvB3",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2551.404929755023!2d-76.40978483430996!3d37.19104830721234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ba808036889a77%3A0xeb239ffac7d11a72!2s200%20Hansford%20Ln%2C%20Seaford%2C%20VA%2023696!5e1!3m2!1sen!2sus!4v1721766040670!5m2!1sen!2sus",
    parcelDescription: `Sweet country corner on the backside of Seaford Virginia. It's not hard to fall in love with this quaint area. Ten minutes from route 17 and still quiet as could be. On your way in be sure to pick up a beverage from the classic Seaford Market. 
    
    This open lot in Seaford would be Ideal for you new home or homes! 2.51 acres on paved road with utilities at the street!!! Buyer to do own Due Diligence. Currently horse pasture with barn and horse; horse (Honey) is friendly. 20 ft gate on Hansford. Make sure gate is closed.`,
    parcelData: {
      gps: "37.19085, -76.40781",
      totalAcres: "2.51",
      landSqft: "109,336",
      assessedYr: "2023",
      assessedValue: "$118,400",
      censusTract: "0504022002",
      landValue: "$118,300",
      landUseType: "Residential",
      county: "York",
      waterServiceType: "Public",
      parcelId: "V08A22694861",
      taxAmount: "$911.68",
    },
    contact: {
      name: "FirstName LastName",
      email: "email@gmail.com",
      phone: "tel:+17577777777",
      phoneDisplay: "(757)###-####",
      website: "https://www.google.com",
      displayWebsite: "www.google.com",
      photoLink: "https://i.postimg.cc/NYYMXDMF/sun.png",
    },
    photos: {
      feature: `https://i.postimg.cc/PX3V1GL1/DSC01270-feature-landscape.png`,
      featurePortrait: `https://i.postimg.cc/FvDBMSP1/DSC01270-feature-portrait.png`,
      portfolio: `https://i.postimg.cc/BsbV4vyp/DSC01270-portfolio.png`,
      parcel: `https://i.postimg.cc/nn4d09jF/parcel-sat-square.png`,
    },
    panoPhotos: {
      sideA: {
        labeledParcel: `https://i.postimg.cc/ZZQDRSzS/parcel-non-sat-A.png`,
        pano: `https://i.postimg.cc/GcbXN3Gk/Panorama-A.png`,
      },
      sideB: {
        labeledParcel: `https://i.postimg.cc/tpVvnM9V/parcel-non-sat-B.png`,
        pano: `https://i.postimg.cc/sVKTRF4b/Panorama-B.png`,
      },
      sideC: {
        labeledParcel: `https://i.postimg.cc/YtKn6zSD/parcel-non-sat-C.png`,
        pano: `https://i.postimg.cc/tqT2qrrJ/Panorama-C.png`,
      },
    },
    lightboxPhotos: {
      sidea1: `https://i.postimg.cc/nH7QvqC2/DSC01270-edited.png`,
      sidea2: `https://i.postimg.cc/38T09WGT/DSC01275-edited.png`,
      sidea3: `https://i.postimg.cc/mTkYFcYp/DSC01263-edited.png`,
      sidea4: `https://i.postimg.cc/VYbXD3Hj/DSC01265-edited.png`,
      sidea5: `https://i.postimg.cc/V1wXWZ9C/DSC01267-edited.png`,
      sidea6: `https://i.postimg.cc/JrNZWj80/DSC01268-edited.png`,
    },
  },
  "2045-Hampton-Hwy-Yorktown-VA-23693": {
    siteName: "2045 Hampton Hwy, Yorktown, VA 23693",
    askingPrice: "$130,000",
    acerage: "0.95",
    photoDate: "July 17th, 2024",
    yTlink: "https://www.youtube.com/embed/L20j2TQtN7c?si=GcDVMVMdGHcR9WGM",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2875.327330275765!2d-76.43379622454277!3d37.10705917216119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ba8221ba78cc9f%3A0x57287e494ba0d5a3!2s2045%20Hampton%20Hwy%2C%20Yorktown%2C%20VA%2023693!5e1!3m2!1sen!2sus!4v1721660476605!5m2!1sen!2sus",
    parcelDescription: `Undeveloped 0.95 acre lot on the far side of York County near Hampton, VA. The lot can be accessed by a driveway via Hampton Hwy or by a gravel road (Swain Ln) on the backside. The property currently has one very old building.`,
    parcelData: {
      gps: "37.10696, -76.4312",
      totalAcres: "0.95",
      landSqft: "41,382",
      assessedYr: "2023",
      assessedValue: "$118,200",
      censusTract: "0502053010",
      landValue: "$117,000",
      landUseType: "Commercial",
      county: "York",
      waterServiceType: "Well",
      parcelId: "U02A11554118",
      taxAmount: "$910.14",
    },
    contact: {
      name: "FirstName LastName",
      email: "email@gmail.com",
      phone: "tel:+1757777777",
      phoneDisplay: "(757) ###-####",
      website: "https://www.google.com",
      displayWebsite: "www.google.com",
      photoLink: "https://i.postimg.cc/NYYMXDMF/sun.png",
    },
    photos: {
      feature: `https://i.postimg.cc/yKtmLHcW/DSC01229-feature-landscape.png`,
      featurePortrait: `https://i.postimg.cc/XjR9qf49/DSC01229-feature-portrait.png`,
      portfolio: `https://i.postimg.cc/3YTHKDSC/portfolio-DSC01229.png`,
      parcel: `https://i.postimg.cc/DvJq7jk2/parcel-square.png`,
    },
    panoPhotos: {
      sideA: {
        labeledParcel: `https://i.postimg.cc/9W4pfFy5/parcel-nonsat-A.png`,
        pano: `https://i.postimg.cc/Gb3RV6Gs/Panorama-A.png`,
      },
      sideB: {
        labeledParcel: `https://i.postimg.cc/VzZg1brW/parcel-nonsat-B.png`,
        pano: `https://i.postimg.cc/J7h5HbFn/Panorama-B.png`,
      },
      sideC: {
        labeledParcel: `https://i.postimg.cc/6BpcDh3Q/parcel-nonsat-C.png`,
        pano: `https://i.postimg.cc/k9McHm5m/Panorama-C.png`,
      },
    },
    lightboxPhotos: {
      driveway1: `https://i.postimg.cc/Mq5C6MrJ/DSC01217.png`,
      driveway2: `https://i.postimg.cc/L9jWrnZ7/DSC01218.png`,
      house1: `https://i.postimg.cc/zD5Q58Cf/DSC01207.png`,
      house2: `https://i.postimg.cc/NY7VD2t2/DSC01215.png`,
      house3: `https://i.postimg.cc/8NZYQ8j1/DSC01221.png`,
      house4: `https://i.postimg.cc/SkSPvQQT/DSC01223.png`,
      debris1: `https://i.postimg.cc/LHYw3y4m/DSC01209.png`,
      debris2: `https://i.postimg.cc/7qLpfHpG/DSC01210.png`,
      drone: `https://i.postimg.cc/7xFcsgb0/DJI-0018.jpg`,
    },
  },
  "9131-George-Washington-Memorial-Hwy-Yorktown-VA-23692": {
    siteName: "9131 George Washington Memorial Hwy, Yorktown, VA 23692",
    askingPrice: "$98,000",
    acerage: "0.92",
    photoDate: "June 28th, 2024",
    yTlink: "https://www.youtube.com/embed/50cykzmXMgs?si=t5XSJsh9LqXGm5Ar",
    mapLink:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2871.7326032667843!2d-76.50327692453793!3d37.20164497213533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b07fd56ebb7a0f%3A0x84b339944ca123b8!2s9131%20George%20Washington%20Memorial%20Hwy%2C%20Yorktown%2C%20VA%2023692!5e1!3m2!1sen!2sus!4v1720540288201!5m2!1sen!2sus",
    parcelDescription: `Nicely wooded .918 acre lot with +- 200 feet of frontage on US Route 17 across from York High School. Zoned LB Limited Business which can be used for either commercial or residential development.

            Of the four parcel sides, only two had accessible views as designated by letters A and B. The front side faces route 17 and the rear backs up to a quiet neighborhood called Falcon Crest. Across the street is York County's oldest high school, York High. The side designated by the letter B bumps up against an empty lot that once used to be a camp site. The parcel is not even a half mile away from National Park Service property.
            
            `,
    parcelData: {
      gps: "37.20165,-76.5007",
      totalAcres: "0.92",
      landSqft: "39,988",
      assessedYr: "2023",
      assessedValue: "$108,600",
      censusTract: "0503063001",
      landValue: "$108,600",
      landUseType: "Commerical",
      county: "York",
      waterServiceType: "Public",
      parcelId: "Q09A01583141",
      taxAmount: "$836.22",
    },
    contact: {
      name: "FirstName LastName",
      email: "email@gmail.com",
      phone: "tel:+17577750506",
      phoneDisplay: "(757) ###-####",
      website: "https://www.google.com",
      displayWebsite: "www.google.com",
      photoLink: "https://i.postimg.cc/NYYMXDMF/sun.png",
    },
    photos: {
      feature: `https://i.postimg.cc/4fPLYQfK/DSC01138-feature-16-7-7.png`,
      featurePortrait: `https://i.postimg.cc/MqS7CQqN/DSC01138-feature-portrait.png`,
      portfolio: `https://i.postimg.cc/NtCMfbm8/DSC01138-portfolio-pic.png`,
      parcel: `https://i.postimg.cc/V1151B0q/parcel-photo.png`,
    },
    panoPhotos: {
      sideA: {
        labeledParcel: `https://i.postimg.cc/mRbSzsxn/parcel-side-A.png`,
        pano: `https://i.postimg.cc/3KWbxq66/pano-A-black.png`,
      },
      sideB: {
        labeledParcel: `https://i.postimg.cc/xYt3L4n1/parcel-side-B.png`,
        pano: `https://i.postimg.cc/p2xcDTpw/pano-B-black.png`,
      },
    },
    lightboxPhotos: {
      sidea1: `https://i.postimg.cc/NtVSHBwb/a1-DSC01137.png`,
      sidea2: `https://i.postimg.cc/BqhWyKkG/a2-DSC01133.png`,
      sidea3: `https://i.postimg.cc/mbTRKRr7/a3-DSC01134.png`,
      sidea4: `https://i.postimg.cc/09xLynqs/a4-DSC01138.png`,
      sidea5: `https://i.postimg.cc/ssJTwRbk/a5-DSC01139.png`,
      sidea6: `https://i.postimg.cc/TRKQd5hx/a6-DSC01136.png`,
      sideb1: `https://i.postimg.cc/vMt7dtX9/b1-DSC01167.png`,
      sideb2: `https://i.postimg.cc/kMqxb2MB/b2-DSC01165.png`,
      sideb3: `https://i.postimg.cc/vMs7jN7b/b3-DSC01164.png`,
    },
  },
};

export default siteConfig;
