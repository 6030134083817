import React, { Suspense } from "react";
import { PuffLoader } from "react-spinners";
import hero_portrait from "./images/DSC01116-feature-portrait.png";
import hero_landscape from "./images/DSC01116-main-16-7-feature.png";
import LandscapeHeader from "./LandscapeHeader";
import LandscapeHeaderMobile from "./LandscapeHeaderMobile";
import PortraitHeaderMobile from "./PortraitHeaderMobile";
const ServicesPricing = React.lazy(() => import("./ServicesPricing.js"));
const Portfolio = React.lazy(() => import("./Portfolio.js"));
const Contact = React.lazy(() => import("./Contact.js"));

export default function Main() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return (
    <>
      {isMobile ? (
        <div>
          <div className="landscape:hidden">
            <PortraitHeaderMobile />
            <Suspense
              fallback={
                <div className="h-screen flex items-center justify-center">
                  <PuffLoader />
                </div>
              }
            >
              <img
                className="rounded shadow-[0px_5px_5px_0px_rgba(0,_0,_0,_0.60)] mb-2"
                src={hero_portrait}
                alt="panorama"
                loading="eager"
              />
            </Suspense>

            <Suspense
              fallback={
                <div className="h-screen flex items-center justify-center">
                  <PuffLoader />
                </div>
              }
            >
              {/* eslint-disable-next-line */}
              <a id="services" className="anchor" style={{ top: "-109px" }}></a>
              {/* eslint-disable-next-line */}
              <a id="pricing" className="anchor" style={{ top: "-109px" }}></a>
              <ServicesPricing />
              {/* eslint-disable-next-line */}
              <a
                id="portfolio"
                className="anchor"
                style={{ top: "-109px" }}
              ></a>
              <Portfolio />
              {/* eslint-disable-next-line */}
              <a id="contact" className="anchor" style={{ top: "-109px" }}></a>
              <Contact />
            </Suspense>
          </div>

          <div className="portrait:hidden">
            <LandscapeHeaderMobile />
            <Suspense
              fallback={
                <div className="h-screen flex items-center justify-center">
                  <PuffLoader />
                </div>
              }
            >
              <img
                className="rounded shadow-[0px_5px_5px_0px_rgba(0,_0,_0,_0.60)] mb-2"
                src={hero_landscape}
                alt="panorama"
                loading="eager"
              />
            </Suspense>
            <Suspense
              fallback={
                <div className="h-screen flex items-center justify-center">
                  <PuffLoader />
                </div>
              }
            >
              {/* eslint-disable-next-line */}
              <a id="services" className="anchor" style={{ top: "-109px" }}></a>
              {/* eslint-disable-next-line */}
              <a id="pricing" className="anchor" style={{ top: "-109px" }}></a>
              <ServicesPricing />
              {/* eslint-disable-next-line */}
              <a
                id="portfolio"
                className="anchor"
                style={{ top: "-109px" }}
              ></a>
              <Portfolio />
              {/* eslint-disable-next-line */}
              <a id="contact" className="anchor" style={{ top: "-109px" }}></a>
              <Contact />
            </Suspense>
          </div>
        </div>
      ) : (
        <div>
          <LandscapeHeader />
          <Suspense
            fallback={
              <div className="h-screen flex items-center justify-center">
                <PuffLoader />
              </div>
            }
          >
            <img
              className="rounded shadow-[0px_5px_5px_0px_rgba(0,_0,_0,_0.60)]"
              src={hero_landscape}
              alt="panorama"
              loading="lazy"
            />
          </Suspense>
          <Suspense
            fallback={
              <div className="h-screen flex items-center justify-center">
                <PuffLoader />
              </div>
            }
          >
            {/* eslint-disable-next-line */}
            <a id="services" className="anchor" style={{ top: "-109px" }}></a>
            {/* eslint-disable-next-line */}
            <a id="pricing" className="anchor" style={{ top: "-109px" }}></a>
            <ServicesPricing />
            {/* eslint-disable-next-line */}
            <a id="portfolio" className="anchor" style={{ top: "-109px" }}></a>
            <Portfolio />
            {/* eslint-disable-next-line */}
            <a id="contact" className="anchor" style={{ top: "-109px" }}></a>
            <Contact />
          </Suspense>
        </div>
      )}
    </>
  );
}
